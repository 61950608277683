<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="show"
      width="30%"
      :before-close="closeFn">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="一级标签">
          <el-input v-model="form.tagName" style="width: 260px;"></el-input>
        </el-form-item>
        <el-form-item label="二级标签">
          <div v-for="(item,index) of form.wordTagInfoList" :key="index" style="display: inline-block;margin-bottom: 8px;">
            <el-input v-model="form.wordTagInfoList[index].tagInfoName" style="width: 260px;"></el-input>
            <i class="add remove el-icon-remove-outline" v-if="form.wordTagInfoList.length > 1 && type === 1" @click="delTwoFn(index)"></i>
          </div>
          <i class="add el-icon-circle-plus-outline" @click="addTwoFn"></i>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitFn">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'tagsAdd',
  props: {
    show: Boolean,
    title: String,
    type: Number,
    form: {
      type: Object,
      default: () => {
        return {
          tagName: '',
          tagDefault: 0,
          wordTagInfoList: [
            {
              tagInfoDefault: 0,
              tagInfoName: ''
            }
          ]
        }
      }
    }
  },
  data () {
    return {

    }
  },

  methods: {
    submitFn () {
      this.$emit('submit', this.form)
    },
    closeFn () {
      this.$emit('close')
    },
    addTwoFn () {
      this.form.wordTagInfoList.push({
        tagInfoDefault: 0,
        tagInfoName: ''
      })
    },
    delTwoFn (index) {
      this.form.wordTagInfoList.splice(index, 1)
    }
  }
}
</script>

<style scoped lang="less">
.add{
  font-size: 22px;
  color: #409EFF;
  margin-left: 10px;
  cursor: pointer;
  position: relative;
  top: 4px;
}
.remove{
  color: #F56C6C;
}
</style>
