<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>学术管理</el-breadcrumb-item>
      <el-breadcrumb-item>标签管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-page-header @back="goBack"></el-page-header>
    <!-- 筛选 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row">
        <el-col>
          <span class="title_class">条件筛选</span>
        </el-col>
      </el-row>
      <el-form ref="queryFormRef" :model="queryForm" label-width="80px">
        <el-row :gutter="50">
          <el-col :span="8">
            <el-form-item label="一级标签" prop="cType">
              <el-input
                v-model="queryForm.tagName"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row :gutter="20" type="flex" justify="end">
        <el-col :span="2">
          <el-button
            type="primary"
            style="width: 100%"
            @click="searchFn"
            >查询</el-button
          >
        </el-col>
        <el-col :span="2">
          <el-button type="primary" plain style="width: 100%" @click="reset"
            >重置</el-button
          >
        </el-col>
      </el-row>
    </el-card>
    <!-- 列表 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row">
        <el-col :span="5">
          <span class="title_class">标签管理</span>
        </el-col>
        <el-col :span="19" style="text-align: right;">
          <el-button type="primary" @click="addTagFn">新增</el-button>
        </el-col>
      </el-row>
      <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="dataList"
        border
        stripe
      >
        <el-table-column label="序号" type="index"></el-table-column>
        <el-table-column label="一级标签" prop="tagName" width="220px"></el-table-column>
        <el-table-column label="二级标签" prop="secondTagName"></el-table-column>
        <el-table-column label="系统默认" prop="tagDefault" width="160px">
          <template slot-scope="scope">
            <el-tag :type="scope.row.tagDefault === 1 ? '' : 'success'" size="mini">{{ scope.row.tagDefault === 1 ? '默认' : '自定义' }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="140px">
          <template slot-scope="scope">
            <el-button
              type="text"
              icon="el-icon-edit"
              size="mini"
              :disabled="scope.row.tagDefault === 1"
              @click="editFn(scope.row)"
            >编辑</el-button>
            <el-button
              type="text"
              icon="el-icon-delete"
              size="small"
              :disabled="scope.row.tagDefault === 1"
              :class="scope.row.tagDefault === 1 ? '' : 'del-btn'"
              @click="deleteDialog(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.pageNum"
        :page-sizes="[10, 20, 40, 80]"
        :page-size="queryForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 标签弹窗 -->
    <tagsPop :show="tagPopVisible" @submit="saveTagFn" @close="closeTagFn" :title="tagPopTitle" :form="tagPopInfo" :type="tagPopType"></tagsPop>
  </div>
</template>
<script>
import tagsPop from './components/tags_add.vue'
import { queryWordTagInfoList, updateWordTagInfoList, deleteWordTagInfoList } from '@/http/api'
export default {
  components: {
    tagsPop
  },
  data () {
    return {
      tagPopVisible: false,
      tagPopTitle: '',
      tagPopType: 1,
      tagPopInfo: {},
      dataList: [],
      queryForm: {
        pageNum: 1,
        pageSize: 10,
        tagName: ''
      },
      total: 0
    }
  },
  created () {
    this.queryForm.tagName = this.$route.query.n
    this.getList()
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    // 自定义标签
    addTagFn () {
      this.tagPopVisible = true
      this.tagPopTitle = '添加标签'
      this.tagPopType = 1
      this.tagPopInfo = {
        tagName: '',
        tagDefault: 0,
        wordTagInfoList: [
          {
            tagInfoDefault: 0,
            tagInfoName: ''
          }
        ]
      }
    },
    saveTagFn (form) {
      console.log(form)
      updateWordTagInfoList(form).then(res => {
        this.tagPopVisible = false
        this.$message.success(this.tagPopType === 1 ? '添加成功' : '修改成功')
        this.searchFn()
      })
    },
    closeTagFn () {
      this.tagPopVisible = false
    },
    getList () {
      queryWordTagInfoList(this.queryForm).then(res => {
        res.data.list.forEach(item => {
          item.secondTagName = item.wordTagInfoList.map(tag => tag.tagInfoName).join('，')
        })
        this.dataList = res.data.list
        this.total = res.data.total
      })
    },
    editFn (item) {
      console.log(item)
      item.wordTagInfoListEdit = item.wordTagInfoList.map(t => {
        const { id, tagId, tagInfoDefault, tagInfoName } = t
        return { id, tagId, tagInfoDefault, tagInfoName }
      })
      const info = {
        id: item.id,
        tagDefault: item.tagDefault,
        tagName: item.tagName,
        wordTagInfoList: item.wordTagInfoListEdit
      }
      console.log(info)
      this.tagPopInfo = info
      this.tagPopVisible = true
      this.tagPopTitle = '修改标签'
      this.tagPopType = 0
    },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.getList()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.getList()
    },
    searchFn () {
      this.queryForm.pageNum = 1
      this.getList()
    },
    reset () {
      this.queryForm.tagName = ''
      this.queryForm.pageNum = 1
      this.queryForm.pageSize = 10
      this.getList()
    },
    deleteDialog (id) {
      this.$confirm('确认删除标签?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 调删除接口
        deleteWordTagInfoList(id).then(res => {
          this.searchFn()
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        })
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // })
      })
    }
  }
}
</script>
<style lang="less" scoped>
.del-btn{
  color: #f56c6c;
}
</style>
